import validate from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/node_modules/nuxt/dist/pages/runtime/validate.js";
import app_45version_45global from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/middleware/appVersion.global.ts";
import check_45send_45wish_45global from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/middleware/checkSendWish.global.ts";
import maintenance_45global from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/middleware/maintenance.global.ts";
import persist_45query_45global from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/middleware/persistQuery.global.js";
import manifest_45route_45rule from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  app_45version_45global,
  check_45send_45wish_45global,
  maintenance_45global,
  persist_45query_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}