export default {
  standard: [
    'standard001pro',
    'standard002pro',
    'standard003pro',
    'standard004pro',
    'standard005pro',
    'standard006pro',
    'standard007pro',
    'standard008pro',
    'standard009pro',
    'standard010pro',
    'standard011pro',
    'standard012pro',
    'standard013pro',
    'standard014pro',
    'standard015pro',
    'standard016pro',
    'standard017pro',
    'standard018pro',
    'standard019pro',
    'standard020pro',
    'standard021pro',
    'standard022pro',
    'standard023pro',
    'standard025pro',
    'standard026pro',
    'standard027pro',
  ],
  remove: [
    'remove001pro',
    'remove002pro',
    'remove003pro',
    'remove004pro',
    'remove005pro',
    'remove006pro',
    'remove007pro',
    'remove008pro',
    'remove009pro',
  ]
}