import { useAppStateStore } from '~/store/appState'
import { useWishStore } from '~/store/wish'
import { useEventStore } from '~/store/event'
import { realtimeDatabase } from '~/service/firebase_config'
import { ref, get, set, update, remove, onDisconnect, increment } from 'firebase/database'
import { ref as refState} from 'vue'
import dayjs from 'dayjs'
import _debounce from 'lodash/debounce.js'

const trackingAnalytic = () => {
  const route = useRoute()
  const appStateStore = useAppStateStore()
  const wishStore = useWishStore()
  const eventStore = useEventStore()
  const hasRun = refState(false)
  let state = reactive({
    refDateAnalytic: '',
    refMonthAnalytic: '',

  })

  watch(() => route.path, () => {

    // update only thankyou page
    setTimeout(async () => {
      if(route.path === '/thankyou' && hasRun.value === false){ 
        hasRun.value = true
        await Promise.all([
          updateDateAnalytic(),
          updateMonthAnalytic()
        ])
      }

    }, 10);
  })

  async function updateDateAnalytic() {
    const currentDate = dayjs().format('YYYY-MM-DD')
    const countValue = await getTransactionValue('date', currentDate)
    state.refDateAnalytic = `analytic/date/${currentDate}/${eventStore.eventId}`

    let updates:any = {}

    if(typeof countValue === 'number') {
      updates[state.refDateAnalytic] = countValue + 1
    } else {
      updates[state.refDateAnalytic] = increment(1)
    }
    
    return update(ref(realtimeDatabase), updates)
  }


  async function updateMonthAnalytic() {
    const currentMonth = dayjs().format('YYYY-MM')
    const countValue = await getTransactionValue('events', currentMonth)
    state.refMonthAnalytic = `analytic/events/${currentMonth}/${eventStore.eventId}`

    let updates:any = {}

    if(typeof countValue === 'number') {
      updates[state.refMonthAnalytic] = countValue + 1
    } else {
      updates[state.refMonthAnalytic] = increment(1)
    }
    return update(ref(realtimeDatabase), updates)
  }


  async function getTransactionValue(type: string, dateOrMonth: string) {

    try {
      const snapshot = await get(ref(realtimeDatabase, `analytic/${type}/${dateOrMonth}/${eventStore.eventId}`));
      if (snapshot.exists()) {
        return snapshot.val();
      } else {
        console.log("No data available for this ID.");
        return 0;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  return {
    updateDateAnalytic,
    updateMonthAnalytic
  }

}

export default trackingAnalytic