import { defineStore } from 'pinia'
import { db, storage } from '~/service/firebase_config'
import axios from 'axios'

import { useAuthStore } from './auth'
import { useEventStore } from './event'
import { useAppStateStore } from './appState'
import { useTemplateStore } from './template'
import { useRestorePageStore } from './restorePage'

import { makeId } from '~/utils/makeId'
import mockWishList from '~/public/data/mockWishText'
import delay from '~/utils/delay'
import dayjs from 'dayjs'
import _ from 'lodash'
import { i18n } from '~/plugins/i18n'
import { doc, getDoc, onSnapshot, setDoc, where, query, collection, deleteDoc, serverTimestamp, increment, getCountFromServer, getAggregateFromServer, count, sum } from 'firebase/firestore'

import _cloneDeep from 'lodash/cloneDeep.js'
import _forEach from 'lodash/forEach.js'
import _includes from 'lodash/includes.js'
import _sample from 'lodash/sample.js'
import _sortBy from 'lodash/sortBy.js'
import _size from 'lodash/size.js'
import _last from 'lodash/last.js'
import _map from 'lodash/map.js'
import _random from 'lodash/random.js'
import _filter from 'lodash/filter.js'
import _difference from 'lodash/difference.js'
import _keys from 'lodash/keys.js'
import _take from 'lodash/take.js'
import _orderBy from 'lodash/orderBy.js'
import { useUploadStore } from './upload'
import { snsPublishMessage } from '~/service/sns'
import { sqsPublishMessage } from '~/service/sqs'
import { mappingTemplateId } from '~/utils/mappingTemplateId'


const COLL_APPSTATE = 'appState'
const COLL_WISHES = 'wishes'


export const useWishStore = defineStore({
  id: 'wish',
  state: () => {
    return {
      eventWishesCount: 0,
      allMyWishes: [],
      softDeletedWishes: [],
      qrId: null,

      wishPlayground: null as any, // for render select template
      wishForm: null, // form inputs
      wishPreviewData: null as any, // for preview
      isSendWish: false,
      randomWishText: {},
      usedRandomWishTextId: {},

      //current wish
      wishDocId: null as any,
      wishData: null as any,
    }
  },
  actions: {
    async main() {
      const eventStore = useEventStore()
      const authStore = useAuthStore()

      await Promise.all([
        this.realtimeAllCountEventWishes(),
        this.realtimeSnapshotMyWishes(),
      ])

      await this.createNewDoc()
    },

    realtimeAllCountEventWishes() {
      const p = new Promise(async (resolve) => {
        const eventStore = useEventStore()
        const eventId = eventStore.eventId

        if(!eventId) {
          this.eventWishesCount = 0
          resolve(true)
          return
        }

        if(_includes(eventStore?.package, 'pro')) {
          resolve(true)
          return
        }

        const currentDate = new Date();
        const dateNow = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59);

        const coll = collection(db, `events/${eventId}/${COLL_WISHES}`);
        const q = query(coll, where("eventId", "==", eventId), where("timestamp", "<=", dateNow));
        const snapshot = await getCountFromServer(q);
        const data = snapshot.data()
        this.eventWishesCount = data?.count || 0

        resolve(true)
      })

      return p

    },

    realtimeSnapshotMyWishes() {
      const p = new Promise(async resolve => {

        const authStore = useAuthStore()
        const eventStore = useEventStore()
        const eventId = eventStore.eventId

        const wishRef = collection(db, `events/${eventId}/wishes`);

        const userId = authStore.userId
        const q = query(wishRef, where("eventId", "==", eventStore.eventId), where("userId", "==", userId));

        onSnapshot(q, (querySnapshot) => {
          let allWishList: any = []
          let deletedList: any = []
          querySnapshot.forEach(async (doc) => {
            const data = doc.data()

            // --- filter : wishes version 1 only ----
            if(!data.version || data.version !== 'v2' ) {
              const objectData = Object.assign({}, data, { docId: doc.id })

              if(data?.deleted) {
                deletedList.push(objectData)
              } else {
                allWishList.push(objectData)  
              }
            }
          });

          this.allMyWishes = _sortBy(allWishList, 'wish.timestamp')
          this.softDeletedWishes = _sortBy(deletedList, 'wish.timestamp')

          resolve(true)
        })
      })
      return p
    },

    async createNewDoc() {
      const eventStore = useEventStore()
      const authStore = useAuthStore()

      //if no wish doc, create new wish doc
      const localUnique = window.localStorage.getItem('unique')
      let docId = ''

      if(localUnique) {
        docId = `${eventStore.eventId}:${authStore.userId}:${localUnique}`
      } else {
        const unique = makeId(3)
        window.localStorage.setItem('unique', makeId(3))
        docId = `${eventStore.eventId}:${authStore.userId}:${unique}`
      }

      this.wishDocId = docId
      console.log('docId --->', docId)

      const docRef = doc(db, `events/${eventStore.eventId}/wishes/${docId}`)
      await setDoc(docRef, {
        eventId: eventStore.eventId,
        userId: authStore.userId
      })
      
      //snapshot
      onSnapshot(docRef, (doc) => {
        this.wishData = doc.data()
      })
    },

    async clearGuestImageObj() {
      const eventStore = useEventStore()
      const authStore = useAuthStore()
      const eventId = eventStore?.eventId
        
      const docRef = doc(db, `events/${eventId}/wishes/${this.wishDocId}`)
      console.log('clear guest image ')
      return setDoc(docRef, {
        guest: {}
      }, { merge: true})
    },

    async updateGuestImageObj(guestData = null) {
      const eventStore = useEventStore()
      const authStore = useAuthStore()
      const eventId = eventStore?.eventId

      let imageData = {}

      if(guestData) {
        imageData = guestData
      } else {
        // --- event: brand ------
        const nodeName = `${eventStore?.eventId}:${authStore?.userId}:guestImage`
        const guestImage:any = window.localStorage.getItem(nodeName)
        imageData = JSON.parse(guestImage)
      }
      
      if(!imageData || _size(imageData) === 0) {
        return console.log(`missing guest image: ${imageData}`)
      }
      
      // console.log(imageData)
      const docRef = doc(db, `events/${eventId}/wishes/${this.wishDocId}`)
      await setDoc(docRef, {
        guest: imageData
      }, { merge: true})
      console.log('save guest image')

    },

    sendWish(wishPayload: any) {

      const appStateStore = useAppStateStore()
      const templateStore = useTemplateStore()
      const eventStore = useEventStore()
      const restorePageStore = useRestorePageStore()

      const p = new Promise(async (resolve, reject) => {
        const docId: string = this.wishDocId
        const eventId = eventStore.eventId
        let wishData = this.generateWishData(wishPayload)
        console.log(wishData)

        try {
          const docRef = doc(db, `events/${eventId}/wishes/${docId}`)
          await setDoc(docRef, wishData, { merge: true })
          console.log(`saveToFirestore ${docId} success`)

          // -- remove tmp wish page ---
          // await restorePageStore.removeRestorePage()
          //-----------------------------

          await delay(3)
          
          // sqs: build render and send notify
          sqsPublishMessage({
            ...this.wishData,
            docId: docId,
            site: eventStore.site
          })

          appStateStore.loading = false
          
          // --- clear ---
          this.cleanAfterSendWish()
          resolve(true)
        }
        catch (error) {
          console.log(error)
          appStateStore.loading = false
          alert('Sending wish error')
          reject()
        }
      })

      return p
    },

    sendToLineNotify(wishId: string, wishData: any) {
      const eventStore = useEventStore()
      const eventId = eventStore.eventId
      
      const p = new Promise(async (resolve) => {
        const userIdList = ['U31755c7e54ce12ea295e33561895b2ec', 'U79cb50d879d85d2641e2ca77ff9c5816']

        for (let index = 0; index < userIdList?.length; index++) {
          const userId = userIdList[index];

          const lineNotifyId = makeId(10)


          const docRef = doc(db, 'line', lineNotifyId)
          await setDoc(docRef, {
            to: userId,
            text: `New Wish!! 🎉🎉 \n\nwishId : ${wishId}  \neventId: ${eventId} \nuserId: ${wishData?.userId} \ncreateAt: ${dayjs().format('DD-MM-YYYY HH:mm')} `,
            createdAt: new Date().toISOString()
          })
          console.log('create document for line notify success')
        }

        resolve(true)
      })

      return p
    },

    cleanAfterSendWish() {
      const templateStore = useTemplateStore()

      // clear localstorage
      window.localStorage.removeItem('unique') // unique
      window.localStorage.removeItem(this.wishDocId) // form

      this.isSendWish = true

      // --- clear state ---
      this.wishPlayground =  null // for select template
      this.wishForm = null, // form inputs
      this.wishDocId = null 
      // -------------------

      // -- create new wish doc ---
      setTimeout(() => {
        this.createNewDoc()
        templateStore.generateRandomTemplates()
      }, 1000);
    },

    async softDeleteWish(wish: any) {
      const eventStore = useEventStore()
      const eventId = eventStore.eventId

      // --- delete wish document ---
      const docId = wish.docId
      const docRef = doc(db, `events/${eventId}/wishes/${docId}`)
      await setDoc(docRef, {
        deleted: true
      }, {merge: true})
      console.log('soft delete success')
    },

    async removeWish(wish: any) {
      const eventStore = useEventStore()
      const eventId = eventStore.eventId

      // --- delete wish document ---
      const docId = wish.docId
      const docRef = doc(db, `events/${eventId}/wishes/${docId}`)
      await deleteDoc(docRef)

      // // ---- delete image -----
      // await this.removeImages(wish)
    },

    async removeImages(wish: any) {

      console.log(wish)
      if(!wish) {
        return 
      }

      const uploadStore = useUploadStore()

      const promiseList = []
      if(wish?.guest?.result?.id) {
        console.log(wish?.guest?.result?.id)
        promiseList.push(uploadStore.removeOnCloudflare(wish?.guest?.result?.id))
      }

      if(wish?.guest?.resultRemovebg?.id) {
        console.log(wish?.guest?.resultRemovebg?.id)
        promiseList.push(uploadStore.removeOnCloudflare(wish?.guest?.resultRemovebg?.id))
      }

      if(wish?.render?.result?.id) {
        console.log(wish?.render?.result?.id)
        promiseList.push(uploadStore.removeOnCloudflare(wish?.render?.result?.id))
      }

      if(promiseList?.length > 0) {
        await Promise.all(promiseList)
      }
    },

    getRandomWishIdList() {
      const appStateStore =  useAppStateStore()
      const local = appStateStore?.locale || 'th'

      let list = _.cloneDeep(mockWishList)
      if(local === 'en') {
        list = _.filter(mockWishList, (wish) => wish?.lang === 'en')
      }

      let unusedTextList = _.filter(list, (wish) => !this.usedRandomWishTextId[wish.id])
      if (unusedTextList?.length < 10) {
        this.randomWishText = _sample(list)
      } else {
        this.randomWishText = _sample(unusedTextList)
      }
      return this.randomWishText.text

    },

    async getWishBreak(wishText: string) {
      // const endpoint = 'https://words-words-m4gzben4za-as.a.run.app'
      const endpoint = 'https://pythainlp-29drr.ondigitalocean.app/pythainlp2/tokenize'

      try {
        const response = await axios.post(endpoint, {
          text: wishText
        })

        if (response.status === 200) {
          return response.data
        } else {
          return null
        }
      } catch (error) {
        console.log('getWishBreak: something wrong')
        return null
      }
    },

    generateWishData(wishPayload: any) {
      const authStore = useAuthStore()
      const eventStore = useEventStore()
      const uploadStore = useUploadStore()
      const templateStore = useTemplateStore()
      const wishTextRemoveSpace = this.wishForm?.wish?.replace(/[\r\n]{2,}/g, "\n") || ''

      let payload: any = {
        templateId: templateStore.templateId,
        userId: authStore.userId,
        timestamp: serverTimestamp(),
        played: false,
        status: 'approved',
        guest: {
          name: this.wishForm?.guests[0],
          friendOf: this.wishForm?.friendOf || '',
        },
        wish: {
          text: wishTextRemoveSpace,
        },
        version: 'v3',
        site:  eventStore.site
      }

      if(wishPayload) {
        payload = {
          ...wishPayload,
          guest: {
            name: this.wishForm?.guests[0],
            friendOf: this.wishForm?.friendOf || '',
            imageAttrs: wishPayload?.guest?.imageAttrs || {}
          },
          templateId: templateStore.templateId,
          wish: {
            ...wishPayload?.wish,
            text: wishTextRemoveSpace,
          },
          timestamp: serverTimestamp(),
          version: 'v3',
          site:  eventStore.site
        }
      }

      // --- event Type: brand && select to name -----
      const isWeddingEvent =  eventStore.eventType === "wedding";
      const toList = eventStore?.information?.to || [];
      if(!isWeddingEvent && toList?.length > 0 && this.wishForm?.to) {
        payload.guest['to'] = this.wishForm?.to
      }
      // ---------------------------------------------

      if (this.wishForm?.wishBreak) {
        payload.wish['words'] = this.wishForm?.wishBreak
      }

      return payload
    },
  },
  getters: {

    getMyWishList(state) {
      const allWishList = this.allMyWishes

      const filtered = _filter(allWishList, (wish) => wish.wish && (wish.status == 'approved' || wish.status == 'pending'))
      const filteredVersion = _filter(filtered, (wish) => !wish.version || wish?.version !== 'v2')
      const transformList = _map(filteredVersion, (wish) => {
        const wishData = wish
        return wishData
      })

      // const sorted = _sortBy(transformList, 'timestamp')
      // return sorted

      // ------ mapping old templateId -> new templateId --------
      const mappingTemplates = _map(transformList, wish => {
        if(wish?.version == 'v3') {
          return wish
        }

        return {
          ...wish,
          templateId: mappingTemplateId(wish?.templateId)
        }
      })
      const sorted2 = _sortBy(mappingTemplates, 'timestamp')
      return sorted2
      // --------------------------------------------------------
    },

    getHashId(state) {
      const docId = state.wishDocId
      const docIdSplit = docId.split(':')
      return docIdSplit[2]
    }
  }
})

