import { useEventStore } from "~/store/event"
import { useWishStore } from "~/store/wish"

export default defineNuxtRouteMiddleware((to, from) => {
  const eventStore = useEventStore()
  const wishStore = useWishStore()

  const allowPage = ['thankyou', 'preview', 'promptpay', 'promptpay-attach']

  if(wishStore?.isSendWish && !allowPage.includes(to?.name)) {
    window.location.href = window.location.origin + "/?eventId=" + eventStore?.eventId
  }
})