import { useAppStateStore } from '~/store/appState'
import { useWishStore } from '~/store/wish'
import { useEventStore } from '~/store/event'
import { realtimeDatabase } from '~/service/firebase_config'
import { ref, set, remove, onDisconnect } from 'firebase/database'
import { ref as refState} from 'vue'
import dayjs from 'dayjs'
import _debounce from 'lodash/debounce.js'

const trackingPage = () => {
  const route = useRoute()
  const appStateStore = useAppStateStore()
  const wishStore = useWishStore()
  const eventStore = useEventStore()

  const wishForm = computed(() => appStateStore.wishForm)

  let state = reactive({
    refDatabase: '',
  })

  watch(() => route.path, () => {
    updateTrackPage()
  })

  watch(() => state.refDatabase, () => {
    onDisconnect(ref(realtimeDatabase, state.refDatabase)).remove()
  })


  watch(() => wishForm.value?.guests[0], _debounce(() => {
    if(route.path === '/selectUploadAndWish') {
      wishPageTypingInput('NAME')
    }
  }, 1000))

  watch(() => wishForm.value?.wish, _debounce(() => {
    if(route.path === '/selectUploadAndWish') {
      wishPageTypingInput('WISH')
    }
  }, 1000))


  const updateTrackPage = () => {
    const convertPageName = () => {
      switch (route.path) {
        case '/selectImageUpload':
          return 'SELECT_IMAGE'
        case '/selectUploadAndWish':
          return 'WISH'
        case '/wish':
          return 'WISH'
        case '/selectTemplate':
          return 'SELECT_TEMPLATES'
        case '/editor':
          return 'EDITOR'
        case '/thankyou': 
          return 'THANK_YOU'
        case '/mywish/':
          return 'MY_WISH'
        case '/preview/':
          return 'PREVIEW'
        default:
          return 'START'
      }
    }

    if(!wishStore.wishDocId) {
      return
    }
    state.refDatabase = `live/${eventStore.eventId}/mobile/${wishStore.wishDocId}`
    return set(ref(realtimeDatabase, state.refDatabase), `${convertPageName()}______${dayjs().format()}`)
  }


  const wishPageTypingInput = (type: string) => {
    if(!wishStore.wishDocId) {
      return
    }

    const pageName = `INPUT_TYPING_${type}______${dayjs().format()}`
    state.refDatabase = `live/${eventStore.eventId}/mobile/${wishStore.wishDocId}`
    return set(ref(realtimeDatabase, state.refDatabase), `${pageName}`)
  }

  return {
    updateTrackPage,
    wishPageTypingInput,
  }
}

export default trackingPage